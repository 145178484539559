








































import { Component, Prop, Vue} from 'vue-property-decorator';

import StickyHeader from '@/components/StickyHeader.vue';

import { help as PopoverText } from '@/lang/help/spec-create';
import AuthViewBase from "@/views/AuthViewBase";
import AddDeleteTable from "@/components/Table/AddDeleteTable.vue";
import {
  requiredOnChangeRule,
  requiredRule,
} from "@/utils/validation-rules";
import LoadingHandler from "@/utils/loading-handler";
import {CompanyRepository} from "@/repositories/company-repository";
import SpecShareForReceiverRepository, {CreateShareRequestRequest} from "@/repositories/spec/company/spec-share-for-receiver-repository";
import CreateRequestBase from "@/views/spec/companies/receives/create-request/CreateRequestBase.vue";
import RequiredFieldsSection
  from "@/views/spec/companies/receives/create-request/components/RequiredFieldsSection.vue";

@Component({
  components: {
    RequiredFieldsSection,
    CreateRequestBase,
    StickyHeader,
    AddDeleteTable,
  }
})
export default class extends AuthViewBase {
  private readonly PopoverText = PopoverText;

  private initialized = false;

  protected request:CreateShareRequestRequest = {
    targetType: null,
    targetValue: null,
    dueDate: null as any,
    comment: null,
    products: [{
      name: ''
    }],
    requiredFields: []
  };

  private get rules(){
    return {
      products: {
        name: [requiredOnChangeRule],
      }
    };
  }

  private async created() {
    Promise.all([
      (new CompanyRepository()).findById(this.companyId).then(company => {
        this.request.requiredFields = company.setting.specRequestDefaultRequiredFields;
      }),
    ]).then(() => {
      this.initialized = true;
    });
  }

  private addProducts() {
    this.request.products.push({name: ''});
  }

  private async submit() {
    if (!(await (this.$refs.base as CreateRequestBase).validate())) return;

    return LoadingHandler(async () => {
      return (new SpecShareForReceiverRepository(this.companyId)).createRequest(this.request).then(() => {
        this.$router.push({name: 'receive.index'}, () => {
          this.$message({type: 'success', message: this.$t(`規格書の提出依頼を送信しました`)});
        });
      });
    })
  }

}
