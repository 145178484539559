

























































import { Component, Prop, Vue} from 'vue-property-decorator';
import {CreateShareRequestRequest} from "@/repositories/spec/company/spec-share-for-receiver-repository";
import {CompanyClientRepository} from "@/repositories/company/company-client-repository";
import { help as PopoverText } from '@/lang/help/spec-create';
import {CompanyRepository} from "@/repositories/company-repository";
import isAfter from "date-fns/isAfter";
import startOfTomorrow from "date-fns/startOfTomorrow";

@Component({
  components: {
  }
})
export default class extends Vue {
  @Prop({ required: true } ) private companyId!:number;
  @Prop({required: true}) request!: CreateShareRequestRequest;
  @Prop({required: true}) requestingToDisplay!:  { error: string, name: string, loading: boolean };

  private readonly PopoverText = PopoverText;
  private requestedCompanies:{uid: string, name: string, search: string}[] = [];

  private submitterLoaded = false;

  private async created() {
    Promise.all([
      (new CompanyClientRepository(this.companyId)).listSubmitter().then(list => {
        this.requestedCompanies = list.map(v => ({
          uid: v.uid,
          name: v.name,
          search: v.uid + v.name,
        }));
      }),
    ]).then(() => {
      this.submitterLoaded = true;
    });
  }

  private async getRequestingToByUid(uid:string|null) {
    if(!uid) {
      this.requestingToDisplay.name = '';
      return;
    }

    this.requestingToDisplay.loading = true;
    (new CompanyRepository).findByUid(uid)
      .then(c => {
        if (c && c.id !== this.companyId) {
          this.requestingToDisplay.name = c.name;
          this.requestingToDisplay.error = '';
        } else {
          this.requestingToDisplay.name = '';
          this.requestingToDisplay.error = this.$t('事業者がみつかりませんでした');
        }
      }).finally(() => this.requestingToDisplay.loading = false);
  }

  private isSelectableStartDate(date:Date) {
    return isAfter(startOfTomorrow(), date);
  }
}
